import React from "react";
import { motion } from "framer-motion";
import Students from "../../images/student.png";
import PageHeader from "../../components/PageHeader";

function Hep() {
  return (
    <>
      <PageHeader title="Course" />
      <div className="flex md:flex-col gap-8 py-24 sm:py-12 px-64 md:px-32 sm:px-8 justify-center">
        <div className="w-1/3 h-fit relative shadow-md p-2 bg-milk flex flex-col items-center rounded-lg">
          <img
            src={Students}
            alt="founder"
            className="w-[200px] rounded-tl-lg rounded-tr-lg mb-1"
          />
        </div>
        <div className="w-2/3">
          <div className=" text-darkgray text-justify">
            <div className="space-y-4">
              <motion.div
                initial={{ opacity: 0, y: 200 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: { delay: 0.5, duration: 0.5 },
                }}
                viewport={{ once: true }}
                className="leading-6 space-y-3 shadow-md p-8"
              >
                <h2 className="text-2xl font-bold text-seccolor">
                  Health Education and Promotion (HEP):
                </h2>
                <p>
                  The Health Education and Promotion program is dedicated to
                  preparing professionals who specialize in health education,
                  communication, and promotion.
                </p>
                <p>
                  Students learn how to develop and implement health education
                  programs that promote healthy behaviors and lifestyles.
                </p>
                <p>
                  Graduates work in various settings, including schools, public
                  health agencies, and community organizations, to empower
                  individuals and communities to make informed health choices.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hep;
