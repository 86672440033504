import React from "react";
import { motion } from "framer-motion";
import Students from "../../images/student.png";
import PageHeader from "../../components/PageHeader";

function Eht() {
  return (
    <>
      <PageHeader title="Course" />
      <div className="flex md:flex-col gap-8 py-24 sm:py-12 px-64 md:px-32 sm:px-4 justify-center">
        <div className="w-1/3 md:w-full h-fit relative shadow-md p-2 bg-milk flex flex-col items-center rounded-lg">
          <img
            src={Students}
            alt="founder"
            className="w-[200px] rounded-tl-lg rounded-tr-lg mb-1"
          />
        </div>
        <div className="w-2/3 md:w-full">
          <div className=" text-darkgray text-justify">
            <div className="space-y-4">
              <motion.div
                initial={{ opacity: 0, y: 200 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: { delay: 0.5, duration: 0.5 },
                }}
                viewport={{ once: true }}
                className="leading-6 space-y-3 shadow-md p-8"
              >
                <h2 className="text-2xl font-bold text-seccolor">
                  Environmental Health Technology (EHT):
                </h2>
                <p>
                  The Environmental Health Technology program focuses on the
                  management and control of environmental factors that can
                  affect public health.
                </p>
                <p>
                  Students in this program learn how to identify, assess, and
                  mitigate environmental hazards such as pollution, sanitation
                  issues, and disease vectors.
                </p>
                <p>
                  Graduates play a vital role in safeguarding communities from
                  environmental health risks.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Eht;
