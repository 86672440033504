import React from "react";
import { motion } from "framer-motion";

function SectionHeader({ title }) {
  return (
    <div className="section-header">
      <div className="bg-black opacity-80 h-[15vh] md:h-[8vh] w-full rounded-bl-lg rounded-br-lg" />
      <motion.h1
        className="absolute text-center text-4xl md:text-3xl sm:text-2xl text-seccolor font-bold underline underline-offset-8 decoration-purewhite mb-6 capitalize"
        initial={{ opacity: 0, y: -100 }}
        whileInView={{
          opacity: 1,
          y: 0,
          transition: { delay: 0.5, duration: 0.5 },
        }}
        viewport={{ once: true }}
      >
        {title}
      </motion.h1>
    </div>
  );
}

export default SectionHeader;
