import React from "react";
import { motion } from "framer-motion";
import {Link} from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import SectionHeader from "../SectionHeader";

const textShow = {
  initial: {
    opacity: 0,
    x: 200,
  },
  whileInView: {
    opacity: 1,
    x: 0,
  },
};

function About() {
  return (
    <div className="about-us flex lg:flex-col gap-8 sm:gap-4 min-h-[40vh] py-24 px-64 lg:px-32 md:px-24 sm:px-4 items-center justify-center bg-gradient-to-r from-milk to-purewhite text-darkgray overflow-hidden">
      <div className="h-full flex-1  space-y-6 p-4 text-md">
        <SectionHeader title="About FSCHT" />
        <motion.p
          initial={{ opacity: 0, x: 200 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.5, duration: 0.5 },
          }}
          viewport={{ once: true }}
          className="leading-8"
        >
          Welcome to Five Star College of Health Technology, a beacon of hope
          and excellence in Nigeria&apos;s education system. Our institution is
          dedicated to providing world-class education in the healthcare sector,
          equipping students with the skills and knowledge they need to excel in
          Nigeria&apos;s rapidly evolving healthcare industry.
        </motion.p>
        <motion.p
          initial={{ opacity: 0, x: 200 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.5, duration: 0.5 },
          }}
          viewport={{ once: true }}
          className="leading-8"
        >
          The demand for highly skilled healthcare professionals is
          ever-increasing. At Five Star College of Health Technology, we are
          committed to addressing this demand by producing top-tier healthcare
          professionals.
        </motion.p>
        <Link to={"/about"}>
          <button className="font-bold text-seccolor underline underline-offset-4 mt-8 flex items-center gap-1 hover:text-gray hover:ml-2 transition-all duration-150 ease">
            Read on <FaArrowRight />
          </button>
        </Link>
      </div>
    </div>
  );
}

export default About;
