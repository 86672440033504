import React from "react";
import { motion } from "framer-motion";
import Students from "../../images/student.png";
import PageHeader from "../../components/PageHeader";

function Him() {
  return (
    <>
      <PageHeader title="Course" />
      <div className="flex md:flex-col gap-8 py-24 sm:py-12 px-64 md:px-32 sm:px-4 justify-center">
        <div className="w-1/3 md:w-full h-fit relative shadow-md p-2 bg-milk flex flex-col items-center rounded-lg">
          <img
            src={Students}
            alt="founder"
            className="w-[200px] rounded-tl-lg rounded-tr-lg mb-1"
          />
        </div>
        <div className="w-2/3 md:w-full">
          <div className=" text-darkgray text-justify">
            <div className="space-y-4">
              <motion.div
                initial={{ opacity: 0, y: 200 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: { delay: 0.5, duration: 0.5 },
                }}
                viewport={{ once: true }}
                className="leading-6 space-y-3 shadow-md p-8"
              >
                <h2 className="text-2xl font-bold text-seccolor">
                  Health Information Management (HIM):
                </h2>
                <p>
                  Health Information Management is a program that centers on the
                  collection, management, and protection of healthcare data and
                  information.
                </p>
                <p>
                  Students learn to organize and analyze medical records, ensure
                  data accuracy, and maintain patient confidentiality.
                </p>
                <p>
                  Graduates of this program are in high demand in healthcare
                  facilities, ensuring the efficient and secure management of
                  health data, which is crucial for quality patient care and
                  healthcare decision-making.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Him;
