import React from "react";
import { motion } from "framer-motion";
import PageHeader from "../PageHeader";
import {Link} from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import SectionHeader from "../SectionHeader";

const coursesLink = [
  {
    id: 1,
    courseName: "Community Health Extension Workers (CHEW)",
    courseLink: "/department/chew",
  },
  {
    id: 2,
    courseName: "Environmental Health Technology (EHT)",
    courseLink: "/department/eht",
  },
  {
    id: 3,
    courseName: "Health Education and Promotion (HEP)",
    courseLink: "/department/hep",
  },
  {
    id: 4,
    courseName: "Public Health",
    courseLink: "/department/public-health",
  },
  {
    id: 5,
    courseName: "Health Information Management (HIM)",
    courseLink: "/department/him",
  },
];

const textShow = {
  initial: {
    opacity: 0,
    x: 200,
  },
  whileInView: {
    opacity: 1,
    x: 0,
  },
};

function Courses() {
  return (
    <div className="about-us flex lg:flex-col gap-8 min-h-[40vh] py-24 px-64 lg:px-32 md:px-24 sm:px-4 items-center justify-center bg-gradient-to-r from-milk to-purewhite text-darkgray overflow-hidden">
      <div className="h-full flex-1  space-y-6 p-4 text-md">
        <SectionHeader title="Our Courses" />
        <motion.p
          initial={{ opacity: 0, x: 200 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.5, duration: 0.5 },
          }}
          viewport={{ once: true }}
          className="leading-8"
        >
          Our courses have received accreditation from industry-leading and
          relevant professional bodies, ensuring that our curriculum and
          instructional methods adhere to the highest industry standards. You
          can have confidence that your education is not only current but also
          aligned with real-world requirements, guaranteeing the relevance and
          up-to-date nature of your learning experience.
        </motion.p>
        <motion.p
          initial={{ opacity: 0, x: 200 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.5, duration: 0.5 },
          }}
          viewport={{ once: true }}
          className="leading-8"
        >
          Our lecturers are industry professionals who not only possess hands-on
          experience in the subjects they teach but also actively engage in
          their respective fields, balancing their roles as practitioners and
          dedicated researchers.
        </motion.p>
        <motion.p
          initial={{ opacity: 0, x: 200 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.5, duration: 0.5 },
          }}
          viewport={{ once: true }}
          className="leading-8"
        >
          Graduates of these programs are well-equipped to contribute to the
          promotion of community health, environmental safety, health education,
          public health, and the effective management of healthcare information,
          thereby making a significant impact on healthcare outcomes in Nigeria.
        </motion.p>
        <motion.p
          initial={{ opacity: 0, x: 200 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.5, duration: 0.5 },
          }}
          viewport={{ once: true }}
          className="leading-8"
        >
          The College offers the following courses:
        </motion.p>
        <motion.p
          initial={{ opacity: 0, y: 200 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.5, duration: 0.5 },
          }}
          viewport={{ once: true }}
        >
          <span className="space-y-4">
            {coursesLink.map((item) => (
              <span
                className=" text-seccolor hover:text-seccolor/75 hover:underline hover:decoration-maincolor transition-all duration-300 ease font-bold"
                key={item.id}
              >
                <Link
                  to={item.courseLink}
                  className="flex items-center gap-3 space-y-6"
                >
                  <FaArrowRight />
                  {item.courseName}
                </Link>
              </span>
            ))}
          </span>
        </motion.p>
      </div>
    </div>
  );
}

export default Courses;
