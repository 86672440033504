import React from "react";
import { motion } from "framer-motion";
import {
  FaAward,
  FaBus,
  FaWifi,
  FaUserGraduate,
  FaToolbox,
  FaPersonBooth,
  FaCertificate,
  FaCogs,
} from "react-icons/fa";
import PageHeader from "../../components/PageHeader";

const productsList = [
  {
    id: 1,
    title: "Experience Staff",
    content:
      "Our dedicated team of instructors comprises seasoned professionals who bring real-world experience to the classroom.",
    icon: <FaPersonBooth />,
  },
  {
    id: 2,
    title: "Accredited Courses",
    content:
      " Our programs have received accreditation from industry-recognized bodies, which guarantees our curriculum and teaching methods meet the highest industry standards.",
    icon: <FaCertificate />,
  },
  {
    id: 3,
    title: "Academic Excellence with Nigerian Context",
    content:
      "We are committed to producing graduates who can contribute effectively to the Nigerian healthcare landscape.",
    icon: <FaAward />,
  },
  {
    id: 4,
    title: "Practical Experience",
    content:
      "We recognize the importance of practical experience. Our students benefit from hands-on training for effective service.",
    icon: <FaToolbox />,
  },
  {
    id: 5,
    title: "Diverse Course Offerings",
    content:
      "We offer a wide range of departments and courses to cater to various career paths within Nigeria's healthcare sector.",
    icon: <FaUserGraduate />,
  },
  {
    id: 6,
    title: "Free Wi-Fi Access",
    content:
      "To facilitate research, communication, and access to educational resources, we provide free Wi-Fi access across our campus.",
    icon: <FaWifi />,
  },
  {
    id: 7,
    title: "Cutting-edge Facilities",
    content:
      "Our modern campus features cutting-edge facilities and resources. From well-equipped laboratories to advanced technology, our infrastructure supports your learning",
    icon: <FaCogs />,
  },
  {
    id: 8,
    title: "Free Transportation for Students",
    content:
      "We understand the importance of convenient access to our campus. That's why we offer free transportation services to our students.",
    icon: <FaBus />,
  },
];

function about() {
  return (
    <>
      <div>
        <PageHeader title="About Us" />
        <div className="pt-4 md:pt-2">
          <div className="flex flex-col">
            <div className="h-full text-lg  p-8 sm:p-2 rounded-tr-lg rounded-br-lg">
              <div className="text-[20px] md:text-[18px] space-y-6 py-12 px-64 md:py-6 lg:px-24 md:px-12 sm:px-4">
                <motion.p
                  initial={{ opacity: 0, x: -200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  className="leading-8"
                >
                  Welcome to Five Star College of Health Technology, a beacon of
                  hope and excellence in Nigeria&apos;s education system. Our
                  institution is dedicated to providing world-class education in
                  the healthcare sector, equipping students with the skills and
                  knowledge they need to excel in Nigeria&apos;s rapidly
                  evolving healthcare industry.
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: -200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  className="leading-8"
                >
                  We are a fully registered health technology institution with
                  the Corporate Affairs Commission (CAC) . The school is located
                  at Bayan Waya Kontagora Niger State, and equipped with
                  learning facilities for all health, education and life science
                  courses such as learning space and advance specialist
                  laboratories for practicals and demonstration, immersive
                  classrooms, a college clinic, standard ICT unit, library,
                  e-library, cafeteria, and a hall that can contain 500 hundreds
                  students, all of high professional standard which will give
                  our students valuable learning experience.
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: -200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  className="leading-8"
                >
                  The demand for highly skilled healthcare professionals is
                  ever-increasing. At Five Star College of Health Technology, we
                  are committed to addressing this demand by producing top-tier
                  healthcare professionals.
                </motion.p>

                <motion.p
                  initial={{ opacity: 0, x: -200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  className="leading-8"
                >
                  Our institution is deeply rooted in Nigeria&apos;s rich
                  educational heritage, and we strive to uphold the highest
                  standards of academic and ethical excellence.
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: -200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  className="leading-8"
                >
                  The scarcity of qualified professionals has resulted in a
                  significant void and the establishment of the school is
                  timely, given the pressing need within the community for
                  healthcare professionals capable of addressing the health
                  challenges at the primary health center.
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: -200 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  className="leading-8"
                >
                  We are equally dedicated to embracing technology as an
                  integral part of our educational approach, thus enabling our
                  students to remain connected, well-informed, and equipped with
                  the digital skills necessary for success in today&apos;s
                  ever-evolving healthcare landscape. Through the integration of
                  technology, we empower our students to access a wealth of
                  online resources, engage in virtual learning opportunities,
                  and leverage modern tools for research and communication,
                  fostering their growth as tech-savvy healthcare professionals.
                </motion.p>
              </div>
            </div>
          </div>
          <div className="px-32 xl:px-24 lg:px-16 md:px-12 sm:px-8 my-16">
            <h1 className="text-center text-2xl text-seccolor font-bold py-8">
              Why Choose FSCHT:
            </h1>
            <div className="grid w-full grid-cols-9 gap-16 md:gap-8 p-6">
              {productsList.map((items) => (
                <motion.div
                  key={items.id}
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  className="relative col-span-3 lg:col-span-9 bg-purewhite text-seccolor border-maincolor border border-solid p-6 rounded-md"
                >
                  <div className="absolute top-2 -right-3 -z-10 w-[102%] h-[103%] bg-gradient-to-r from-maincolor to-black rounded-md " />
                  <div className="flex flex-col gap-3 sm:gap-6 sm:items-center items-center justify-center h-full">
                    <p className="p-6 text-5xl bg-maincolor text-purewhite rounded-full">
                      {items.icon}
                    </p>
                    <h2 className="text-xl font-bold text-center uppercase">
                      {items.title}
                    </h2>
                    <p className="text-lg sm:text-md text-justify font-light text-darkgray p-3">
                      {items.content}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default about;
