import React from "react";
import { motion } from "framer-motion";
import AImsImage from "../../images/aims.png";
import PageHeader from "../../components/PageHeader";

const aimsO = [
  {
    id: 1,
    heading: "Academic Excellence:",
    content: [
      "To provide a rigorous and comprehensive curriculum that meets international standards while being tailored to the specific needs of Nigeria's healthcare sector.",
      "To maintain a faculty of highly qualified educators who are dedicated to facilitating the learning and growth of our students.",
    ],
  },
  {
    id: 2,
    heading: " Practical Experience:",
    content: [
      "To ensure that our students receive practical training and hands-on experience in their respective fields.",
      "To establish partnerships with healthcare institutions and facilities to facilitate clinical and real-world training opportunities.",
    ],
  },
  {
    id: 3,
    heading: "Cultural and Contextual Awareness:",
    content: [
      "To instill cultural sensitivity and an understanding of the unique challenges and opportunities within Nigeria's diverse healthcare landscape.",
      "To prepare graduates who can adapt to the evolving needs of Nigeria's healthcare sector while respecting cultural nuances.",
    ],
  },
  {
    id: 4,
    heading: "Community Impact:",
    content: [
      "To produce healthcare professionals who are committed to improving the health and well-being of communities across Nigeria.",
      "To encourage community engagement and service, promoting healthcare access and awareness.",
    ],
  },
  {
    id: 5,
    heading: "Research and Innovation:",
    content: [
      "To foster a culture of research and innovation, encouraging students and faculty to contribute to the advancement of healthcare knowledge in Nigeria.",
      "To support research initiatives that address local healthcare challenges and provide solutions.",
    ],
  },
  {
    id: 6,
    heading: "Ethical and Professional Standards:",
    content: [
      "To emphasize the importance of ethical conduct and professionalism in healthcare practice.",
      "To prepare graduates who adhere to the highest ethical standards and contribute positively to the reputation of the healthcare profession.",
    ],
  },
  {
    id: 7,
    heading: "Student Success and Well-Being:",
    content: [
      "To provide a supportive and inclusive learning environment that promotes student success, personal growth, and well-being.",
      "To offer counseling and support services to address the academic and personal needs of our students.",
    ],
  },
  {
    id: 8,
    heading: "Alumni Engagement:",
    content: [
      "To maintain strong connections with our alumni, promoting ongoing professional development and networking opportunities.",
      "To celebrate the achievements of our graduates as they become leaders and change-makers in the healthcare field.",
    ],
  },
  {
    id: 9,
    heading: "Accreditation and Recognition:",
    content: [
      "To pursue and maintain accreditation from relevant regulatory bodies and organizations in Nigeria.",
      "To earn recognition for our institution as a center of excellence in healthcare education.",
    ],
  },
  {
    id: 10,
    heading: "Sustainability and Growth:",
    content: [
      "To ensure the long-term sustainability and growth of Five Star College of Health Technology.",
      "To expand our programs and facilities to meet the increasing demand for healthcare education in Nigeria.",
    ],
  },
];

function AimsObjectives() {
  return (
    <>
      <PageHeader title="Aims and Objectives" />
      <div className="flex md:flex-col gap-8 py-24 sm:py-12 px-64 md:px-32 sm:px-8 justify-center">
        <div className="w-1/3 lg:w-full h-fit relative shadow-md p-2 bg-milk flex flex-col items-center rounded-lg">
          <img
            src={AImsImage}
            alt="founder"
            className="w-[200px] rounded-tl-lg rounded-tr-lg mb-1"
          />
        </div>
        <div className="w-2/3 lg:w-full">
          <div className=" text-darkgray text-justify">
            <ul className="space-y-4">
              {aimsO.map((item) => (
                <motion.li
                  initial={{ opacity: 0, y: 200 }}
                  whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: { delay: 0.5, duration: 0.5 },
                  }}
                  viewport={{ once: true }}
                  key={item.id}
                  className="leading-6 space-y-3 shadow-md p-8"
                >
                  <h2 className="text-2xl font-bold text-seccolor">
                    {item.heading}
                  </h2>
                  {item.content.map((c, i) => (
                    <p key={i}>{c}</p>
                  ))}
                </motion.li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default AimsObjectives;
