import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import {
  FaAt,
  FaEnvelopeOpen,
  FaMapMarker,
  FaMobileAlt,
} from "react-icons/fa";
import ContactImage from "../images/contact.png";
import PageHeader from "../components/PageHeader";
import {Link} from "react-router-dom";

function Contact() {
  return (
    <>
      <PageHeader title="Contact Us" />

      <div className="flex md:flex-col py-12 px-64 md:px-32 sm:px-8">
        <div className="w-1/2  md:w-full">
          <img src={ContactImage} alt="contact" className="w-full" />
        </div>
        <div className="w-1/2  md:w-full">
          <div className="flex flex-col ">
            <div className="flex-1 flex flex-col gap-y-6">
              <div className="space-y-2">
                <h2 className="text-lg md:text-sm font-bold uppercase underline underline-offset-4 text-seccolor">
                  Location:
                </h2>
                <div>
                  <p className="flex gap-1 items-center">
                    <FaMapMarker className="text-seccolor" />: Bayan Waya
                    Kontagora, Niger State.
                  </p>
                </div>
              </div>
              <div className="space-y-2">
                <h2 className="text-lg md:text-sm font-bold uppercase underline underline-offset-4 text-seccolor">
                  Email:
                </h2>
                <p className="flex gap-3 items-center">
                  <FaEnvelopeOpen className="text-seccolor" />:{" "}
                  <span>
                    info@fivestarcollegeofhealthtech.ng, admissions@fivestarcollegeofhealthtech.ng
                  </span>
                </p>
              </div>
              <div className="space-y-2">
                <h2 className="text-lg md:text-sm font-bold uppercase underline underline-offset-4 text-seccolor">
                  Phone:
                </h2>
                <p className="flex gap-1 items-center">
                  <FaMobileAlt className="text-seccolor" />: +234 (703) 811
                  1324, +234 (806) 542 3085
                </p>
              </div>
              <div>
                <h2 className="mb-3">
                  For enquiries or/and Orders, Send us a mail using below
                  options
                </h2>
                <div className="flex items-center space-x-4">
                  <Link
                    to={
                      "mailto:info@fivestarcollegehealthtech.ng?subject=General Enquiries!"
                    }
                    className="bg-gradient-to-r from-seccolor to-black text-sm text-whitesmokecolor hover:bg-gradient-to-l uppercase py-4 px-8 md:py-2 md:px-4 md:text-[11px] rounded-md transition-all duration-300 ease-in-out hover:bg-white"
                  >
                    General Enquiries
                  </Link>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
