import React from "react";
import { motion } from "framer-motion";
import {Link} from "react-router-dom";


const quote = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.5,
    },
  },
};

const singleWord = {
  initial: {
    opacity: 0,
    y: 50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.8,
      duration: 1,
    },
  },
};

const smallText = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0, transition: { delay: 0.8, duration: 1 } },
};

const buttonAni = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0, transition: { delay: 1.5, duration: 1 } },
};

function Hero() {
  const heroText = "Five Star College of Health Technology";
  return (
    <div className="hero relative  text-center">
      <div className="bg-maincolor opacity-80 h-[100vh] md:h-[90vh] -z-10" />
      <div className="absolute top-0 text-purewhite flex flex-col overflow-hidden">
        <div className="flex flex-col items-center justify-center h-[90vh] w-screen space-y-6 px-32 md:px16 sm:px-8">
          <motion.h3
            initial={{ opacity: 0, y: 200 }}
            animate={{ opacity: 1, y: 0, transition: { delay: 0.3 } }}
            className="text-lg font-bold underline underline-offset-4"
          >
            Welcome to:
          </motion.h3>
          <motion.h1
            className="text-6xl lg:text-5xl md:text-4xl md:mt-6 capitalize font-bold mb-3 text-gray-800 2xl:w-[600px] md:w-[400px] sm:w-[250px]"
            variants={quote}
            initial="initial"
            animate="animate"
          >
            {heroText.split(" ").map((word, index) => (
              <motion.span
                key={word + "-" + index}
                className="inline-block"
                variants={singleWord}
                initial="initial"
                animate="animate"
              >
                {word}&nbsp;
              </motion.span>
            ))}
          </motion.h1>
          <motion.p
            variants={smallText}
            initial="initial"
            animate="animate"
            className="text-lg md:text-sm text-gray-600 capitalize 2xl:w-[600px] md:w-[400px] sm:w-[250px] font-bold"
          >
            {" "}
            Nurturing Nigerian Healthcare Excellence with Added Convenience.
          </motion.p>
          <div>
            <Link to={"/about"}>
              <motion.button
                variants={buttonAni}
                initial="initial"
                animate="animate"
                className="bg-gradient-to-r from-purewhite to-gray text-seccolor font-semibold hover:bg-gradient-to-l uppercase py-4 px-12 md:py-3 md:px-6 rounded-md transition-all duration-300 ease-in-out hover:bg-white hover:text-gray-700"
              >
                Know More
              </motion.button>
            </Link>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default Hero;
