import React from "react";
import Layout from "./Layout";
import { motion } from "framer-motion";
import FounderImage from "../images/founder.jpg";
import PageHeader from "./PageHeader";
import {Link} from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import SectionHeader from "./SectionHeader";

function FromFounder() {
  return (
    <div className="flex md:flex-col gap-8 py-24 sm:py-12 px-64 md:px-32 sm:px-8 justify-center">
      <div className="w-1/3 md:w-full relative shadow-md p-2 bg-milk flex flex-col items-center justify-center rounded-lg">
        <img
          src={FounderImage}
          alt="founder"
          className="w-full rounded-tl-lg rounded-tr-lg mb-1"
        />
        <figcaption className="bg-black w-full text-center p-1 text-milk font-bold">
          CP Sani Abdullahi Salihu RTD
        </figcaption>
        <figcaption className="italic">Proprietor</figcaption>
        <Link
          href={"/about/proprietor"}
          className="text-seccolor underline underline-offset-4 font-bold"
        >
          About Me
        </Link>
      </div>
      <div className="w-2/3 md:w-full">
        <SectionHeader title="Welcome Message" />
        <div className=" text-darkgray text-justify space-y-4">
          <motion.p
            initial={{ opacity: 0, y: 200 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
            viewport={{ once: true }}
            className="leading-6"
          >
            On behalf of the entire Five Star College of Health Technology
            community, I extend a warm and hearty welcome to you as you explore
            our college&apos;s website. We are delighted to have you here and
            share our commitment to providing top-notch education and fostering
            a nurturing environment for your academic and personal growth.
          </motion.p>
          <motion.p
            initial={{ opacity: 0, y: 200 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
            viewport={{ once: true }}
            className="leading-6"
          >
            At Five Star College of Health Technology, we pride ourselves on
            being a beacon of excellence in healthcare education, rooted in the
            rich tapestry of Nigerian culture and dedicated to addressing the
            unique healthcare challenges faced by our nation. Our institution is
            not just a place of learning but a launchpad for your aspirations
            and dreams.
          </motion.p>
          <Link href={"/about/welcome-address"}>
            <button className="font-bold text-seccolor underline underline-offset-4 mt-8 flex items-center gap-1 hover:text-gray hover:ml-2 transition-all duration-150 ease">
              Complete Reading <FaArrowRight />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FromFounder;
