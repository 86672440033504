import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import About from "./pages/about/About";
import Contact from "./pages/Contact";
import Admissions from "./pages/Admission";
import WelcomeAddress from "./pages/about/WelcomeAddress";
import AimsObjectives from "./pages/about/AimObjectives";
import MissionVision from "./pages/about/MissionVision";
import Proprietor from "./pages/about/Proprietor";
import Chew from "./pages/department/Chew";
import Eht from "./pages/department/Eht";
import Hep from "./pages/department/Hep";
import PublicHealth from "./pages/department/PublicHealth";
import Him from "./pages/department/Him";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout><Outlet /></Layout>}>
        <Route index element={<Home />} />

        <Route path="about">
          <Route index element={<About />} />
          <Route path="welcome-address" element={<WelcomeAddress />} />
          <Route path="aims-objectives" element={<AimsObjectives />} />
          <Route path="mission-vision" element={<MissionVision />} />
          <Route path="proprietor" element={<Proprietor />} />
        </Route>

        <Route path="admissions">
          <Route index element={<Admissions />} />
        </Route>

        <Route path="department">
          <Route path="chew" element={<Chew />} />
          <Route path="eht" element={<Eht />} />
          <Route path="hep" element={<Hep />} />
          <Route path="public-health" element={<PublicHealth />} />
          <Route path="him" element={<Him />} />
        </Route>

        <Route path="contact">
          <Route index element={<Contact />} />
        </Route>

        {/* Catch all page */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}

export default App;
