import React from "react";
import { motion } from "framer-motion";
import {Link} from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import FounderImage from "../../images/founder.jpg";
import FmKont from "../../images/proprietor-fm-kont.jpg";

function WelcomeAddress() {
  return (
    <>
      <PageHeader title="Welcome Message" />
      <div className="flex md:flex-col gap-8 py-24 sm:py-12 px-64 md:px-32 sm:px-8 justify-center">
        <div className="w-1/3 md:w-full h-fit relative shadow-md p-2 bg-milk flex flex-col items-center rounded-lg">
          <img
            src={FounderImage}
            alt="founder"
            className="w-full rounded-tl-lg rounded-tr-lg mb-1"
          />
          <figcaption className="bg-black w-full text-center p-1 text-milk font-bold">
            CP Sani Abdullahi Salihu RTD
          </figcaption>
          <figcaption className="italic">Proprietor</figcaption>
          <Link
            to={"/about/proprietor"}
            className="text-seccolor underline underline-offset-4 font-bold"
          >
            About Me
          </Link>
        </div>
        <div className="w-2/3 md:w-full">
          <div className=" text-darkgray text-justify space-y-4">
            <motion.p
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="leading-6"
            >
              On behalf of the entire Five Star College of Health Technology
              community, I extend a warm and hearty welcome to you as you
              explore our college&apos;s website. We are delighted to have you
              here and share our commitment to providing top-notch education and
              fostering a nurturing environment for your academic and personal
              growth.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="leading-6"
            >
              At Five Star College of Health Technology, we pride ourselves on
              being a beacon of excellence in healthcare education, rooted in
              the rich tapestry of Nigerian culture and dedicated to addressing
              the unique healthcare challenges faced by our nation. Our
              institution is not just a place of learning but a launchpad for
              your aspirations and dreams.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="leading-6"
            >
              As you navigate through our website, you&apos;ll discover a range
              of programs designed to equip you with the skills, knowledge, and
              values needed to excel in Nigeria&apos;s dynamic healthcare
              sector. We offer specialized courses that cater to the diverse
              facets of healthcare, from community health to health information
              management, all tailored to meet the specific needs of our great
              nation.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="leading-6"
            >
              We understand that the world is evolving, and the healthcare field
              is no exception. Therefore, we are committed to embracing
              technology and innovation, ensuring that you have access to
              cutting-edge resources, online learning tools, and the
              connectivity required to excel in your academic journey.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="leading-6"
            >
              Our focus goes beyond academics. We are dedicated to your holistic
              development, providing support services, extracurricular
              activities, and opportunities to engage with your peers and the
              community.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="leading-6"
            >
              <img
                src={FmKont}
                alt="at-fm"
                className="w-[200px] rounded-tl-lg rounded-tr-lg mb-1"
              />
              <figcaption className="bg-black w-full text-center p-1 text-milk text-sm">
                Radiating Excellence: The Proprietor broadcasting the Five Star
                College Vision at Radio Kontagora!
              </figcaption>
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="leading-6"
            >
              Whether you are a prospective student looking for the ideal
              educational institution to launch your healthcare career or a
              curious visitor seeking information, we encourage you to explore
              our website and learn more about the Five Star College of Health
              Technology experience.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="leading-6"
            >
              We invite you to join us on this transformative journey, where
              academic excellence meets cultural sensitivity, and where your
              aspirations find fertile ground to flourish. Your future in
              healthcare begins here, and we are excited to be a part of your
              success story.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="leading-6"
            >
              Thank you for considering Five Star College of Health Technology,
              and we look forward to welcoming you to our campus and community
              in the near future.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="leading-6"
            >
              Sincerely,
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="leading-6 flex flex-col"
            >
              <span className="font-bold">CP Sani Abdullahi Salihu RTD</span>
              <span className="font-bold italic">Proprietor</span>
              <span>Five Star College of Health Technology</span>
            </motion.p>
          </div>
        </div>
      </div>
    </>
  );
}

export default WelcomeAddress;
