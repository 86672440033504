import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaMobileAlt,
  FaEnvelopeOpen,
  FaLinkedin,
  FaArrowUp,
  FaMapMarker,
} from "react-icons/fa";
import Logo from "../../images/five-star-logo.png";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <footer className=" bg-black text-whitesmokecolor p-16 md:p-12">
      <div className="flex justify-end">
        <FaArrowUp
          className="text-3xl text-maincolor cursor-pointer mb-4"
          onClick={scrollToTop}
        />
      </div>
      <div className="flex md:flex-col gap-6">
        <div className="flex-1 flex flex-col text-center items-center justify-center">
          <img src={Logo} alt="logo" className="w-[300px]" />
          <p className="mt-8">
            For enquiries, please call our dedicated line on +234 (703) 811 1324
          </p>
        </div>
        <div className="flex-1">
          <h2 className="text-lg font-bold uppercase underline underline-offset-4 text-seccolor">
            About us
          </h2>
          <p className="md:text-sm">
            Welcome to Five Star College of Health Technology, a beacon of hope
            and excellence in Nigeria&apos;s education system. Our institution
            is dedicated to providing world-class education in the healthcare
            sector, equipping students with the skills and knowledge they need
            to excel in Nigeria&apos;s rapidly evolving healthcare industry.....
          </p>
          <div className="mt-24 md:mt-8 text-center">
            <p className="mb-2 text-seccolor">Connect with us:</p>
            <span className="flex items-center justify-center gap-6 text-4xl">
              <a
                href="https://web.facebook.com/profile.php?id=100069671148882"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaFacebook className="hover:text-maincolor relative hover:bottom-1 transition-all duration-300 ease-in-out" />
              </a>
              <a
                href="https://twitter.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaTwitter className="hover:text-maincolor relative hover:bottom-1 transition-all duration-300 ease-in-out" />
              </a>
              <a
                href="https://instagram.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaInstagram className="hover:text-maincolor relative hover:bottom-1 transition-all duration-300 ease-in-out" />
              </a>
              <a
                href="https://linkedin.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaLinkedin className="hover:text-maincolor relative hover:bottom-1 transition-all duration-300 ease-in-out" />
              </a>
            </span>
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-y-6 md:text-center md:items-center">
          <div className="space-y-2">
            <h2 className="text-lg md:text-sm font-bold uppercase underline underline-offset-4 text-seccolor">
              Location:
            </h2>
            <div>
              <div>
                <p className="flex gap-1 items-center">
                  <FaMapMarker className="text-maincolor" /> Bayan Waya
                  Kontagora
                </p>
                <p>Niger State.</p>
              </div>
            </div>
          </div>
          <div className="space-y-2">
            <h2 className="text-lg md:text-sm font-bold uppercase underline underline-offset-4 text-seccolor">
              Email:
            </h2>
            <p className="flex gap-1 items-center">
              <FaEnvelopeOpen className="text-maincolor" />:
              info@fivestarcollegehealthtech.ng
            </p>
          </div>
          <div className="space-y-2">
            <h2 className="text-lg md:text-sm font-bold uppercase underline underline-offset-4 text-seccolor">
              Phone:
            </h2>
            <p className="flex gap-1 items-center">
              <FaMobileAlt className="text-maincolor" />: +234 (703) 510 4049,
              +234 (806) 542 3085
            </p>
          </div>
        </div>
      </div>
      <div className="text-sm lg:mt-4 text-center md:text-xs mt-8">
        <span className="text-maincolor font-bold">
          &copy; {new Date().getFullYear()}
        </span>{" "}
        Five Star College of Health Technology | All Right Reserved
      </div>
    </footer>
  );
}

export default Footer;
