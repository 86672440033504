import React from "react";
import { NextUIProvider } from "@nextui-org/react";
import Nav from "./nav/Nav";
import Footer from "./footer/Footer";

function Layout({ children }) {
  return (
    <NextUIProvider>
      <div>
        <Nav />
        <div className="pt-[80px]">{children}</div>
        <Footer />
      </div>
    </NextUIProvider>
  );
}

export default Layout;
