import React from "react";
import { motion } from "framer-motion";
import { FaHome, FaSearch } from "react-icons/fa";
import AImsImage from "../../images/aims.png";
import PageHeader from "../../components/PageHeader";

const missionVision = [
  {
    id: 1,
    title: "Mission",
    content:
      "To empower students with the knowledge, skills, and ethical values required to excel in Nigeria's dynamic healthcare sector. We are committed to providing high-quality education, practical experience, and cultural awareness, preparing our graduates to contribute meaningfully to the health and well-being of the Nigerian population.",
    icon: <FaHome />,
    delay: 0.3,
  },
  {
    id: 2,
    title: "Vision",
    content:
      "To be a leading institution in Nigeria, recognized for producing exceptional healthcare professionals who are both nationally and internationally competitive. We aspire to play a pivotal role in addressing healthcare challenges in Nigeria by nurturing graduates who are not only academically proficient but also culturally sensitive and deeply committed to making a positive impact on healthcare delivery.",
    icon: <FaSearch />,
    delay: 0.6,
  },
];

function MissionVision() {
  return (
    <>
      <PageHeader title="Mission and Vision" />
      <div className="flex md:flex-col gap-8 sm:gap-4 py-24 sm:py-12 px-64 md:px-32 sm:px-4 justify-center">
        <div className=" h-fit relative shadow-md p-2 bg-milk flex flex-col items-center rounded-lg">
          <img
            src={AImsImage}
            alt="founder"
            className="w-[200px] rounded-tl-lg rounded-tr-lg mb-1"
          />
        </div>
        {missionVision.map((items) => (
          <motion.div
            key={items.id}
            initial={{ opacity: 0, y: 100 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: items.delay, duration: 0.5 },
            }}
            viewport={{ once: true }}
            className="w-1/3 md:w-full bg-purewhite text-maincolor border-maincolor border border-solid p-4 rounded-md shadow-2xl shadow-milk text-justify space-y-6"
          >
            <div className="flex items-center justify-center">
              <span className="text-6xl md:text-4xl bg-seccolor text-purewhite rounded-full p-6">
                {items.icon}
              </span>
            </div>
            <h2 className="text-2xl font-bold text-center text-maincolor uppercase">
              {items.title}
            </h2>
            <p className="text-md sm:text-sm font-light text-darkgray p-3">
              {items.content}
            </p>
          </motion.div>
        ))}
      </div>
    </>
  );
}

export default MissionVision;
