import React, { useState } from "react";
import { motion } from "framer-motion";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
  FaMobileAlt,
  FaEnvelopeOpen,
  FaArrowDown,
  FaAngleDown,
} from "react-icons/fa";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
  Button,
} from "@nextui-org/react";
import Logo from "../../images/five-star-logo.png";

const CustomLink = ({ href, title, className = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Link to={href} className={`${className} relative group`}>
      {title}
      <span
        className={`h-[6px] inline-block  bg-gradient-to-r from-seccolor to-purewhite absolute -z-10 left-0 -bottom-0.5 group-hover:w-full  hover:text-gray text-gray transition-[width] ease duration-500 ${
          currentPath === href ? "w-full" : "w-0"
        }`}
      >
        &nbsp;
      </span>
    </Link>
  );
};

const CustomMobileLink = ({ href, title, className = "", toggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const handleClick = () => {
    toggle();
    navigate(href);
  };
  return (
    <button
      href={href}
      className={`${className} relative group uppercase`}
      onClick={handleClick}
    >
      {title}
      <span
        className={`h-[6px] inline-block  bg-gradient-to-r from-seccolor to-purewhite absolute left-0 -bottom-0.5 group-hover:w-full transition-[width] ease duration-500 ${
          currentPath === href ? "w-full" : "w-0"
        }`}
      >
        &nbsp;
      </span>
    </button>
  );
};

function Nav({ href }) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;


  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="shadow-lg fixed z-30 w-full bg-purewhite">
      <div className="flex justify-between items-center px-48 py-2 relative xl:px-24 lg:px-12 md:8 sm:px-4">
        <Link href={"/"}>
          <img src={Logo} alt="Logo" className="w-[70px]" />
        </Link>
        <div className="space-x-5 text-gray-400 uppercase lg:hidden">
          <CustomLink href={"/"} title="Home">
            `` Home
          </CustomLink>
          <Dropdown>
            <DropdownTrigger>
              <Button
                className="relative group uppercase outline-none bg-none m-0 text-[18px]"
                href={"#"}
                title="About us"
                variant="light"
              >
                About us <FaAngleDown />
                <span
                  className={`h-[6px] inline-block  bg-gradient-to-r from-seccolor to-purewhite absolute -z-10 left-0 -bottom-0.5 group-hover:w-full  hover:text-gray text-gray transition-[width] ease duration-500 ${
                    currentPath === href ? "w-full" : "w-0"
                  }`}
                >
                  &nbsp;
                </span>
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions">
              <DropdownItem key="fscht">
                <Link to={"/about"}>About FSCHT</Link>
              </DropdownItem>
              <DropdownItem key="proprietor">
                <Link to={"/about/welcome-address"}>
                  Proprietor&apos;s Welcome Address
                </Link>
              </DropdownItem>
              <DropdownItem key="aims">
                <Link to={"/about/aims-objectives"}>Aims and Objectives</Link>
              </DropdownItem>
              <DropdownItem key="mission">
                <Link to={"/about/mission-vision"}>MIssion and Vision</Link>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <CustomLink href={"/admissions"} title="Admissions">
            Admissions
          </CustomLink>
          <Dropdown>
            <DropdownTrigger>
              <Button
                className="relative group uppercase outline-none bg-none m-0 text-[18px]"
                href={"#"}
                title="Academic Programmes"
                variant="light"
              >
                Academic Programmes <FaAngleDown />
                <span
                  className={`h-[6px] inline-block  bg-gradient-to-r from-seccolor to-purewhite absolute -z-10 left-0 -bottom-0.5 group-hover:w-full  hover:text-gray text-gray transition-[width] ease duration-500 ${
                    currentPath === href ? "w-full" : "w-0"
                  }`}
                >
                  &nbsp;
                </span>
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions">
              <DropdownItem key="chew">
                <Link to={"/department/chew"}>
                  Community Health Extension Workers (CHEW)
                </Link>
              </DropdownItem>
              <DropdownItem key="eht">
                <Link to={"/department/eht"}>
                  Environmental Health Technology (EHT)
                </Link>
              </DropdownItem>
              <DropdownItem key="hep">
                <Link to={"/department/hep"}>
                  Health Education and Promotion (HEP)
                </Link>
              </DropdownItem>
              <DropdownItem key="p-health">
                <Link to={"/department/public-health"}>Public Health</Link>
              </DropdownItem>
              <DropdownItem key="him">
                <Link to={"/department/him"}>
                  Health Information Management (HIM)
                </Link>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <CustomLink href={"/contact"} title="Contact">
            Contact us
          </CustomLink>
        </div>

        {isOpen ? (
          <motion.div
            className="min-w-[70vw] flex flex-col justify-center items-center z-30 space-y-3 bg-maincolor/90 rounded-lg backdrop-blur-md py-32 px-12 text-purewhite fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 "
            initial={{ scale: 0, opacity: 0, x: "-50%", y: "-50%" }}
            animate={{ scale: 1, opacity: 1, transition: { duration: 0.5 } }}
          >
            <CustomMobileLink href={"/"} title="Home" toggle={handleClick}>
              Home
            </CustomMobileLink>
            <Dropdown>
              <DropdownTrigger>
                <Button
                  className="relative group uppercase outline-none bg-none m-0 text-purewhite text-[18px]"
                  href={"#"}
                  title="About us"
                  variant="light"
                >
                  About us <FaAngleDown />
                  <span
                    className={`h-[6px] inline-block  bg-gradient-to-r from-seccolor to-purewhite absolute -z-10 left-0 -bottom-0.5 group-hover:w-full  hover:text-gray text-gray transition-[width] ease duration-500 ${
                      currentPath === href ? "w-full" : "w-0"
                    }`}
                  >
                    &nbsp;
                  </span>
                </Button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Static Actions">
                <DropdownItem key="fscht">
                  <Link to={"/about"}>About FSCHT</Link>
                </DropdownItem>
                <DropdownItem key="proprietor">
                  <Link to={"/about/welcome-address"}>
                    Proprietor&apos;s Welcome Address
                  </Link>
                </DropdownItem>
                <DropdownItem key="aims">
                  <Link to={"/about/aims-objectives"}>
                    Aims and Objectives
                  </Link>
                </DropdownItem>
                <DropdownItem key="mission">
                  <Link to={"/about/mission-vision"}>MIssion and Vision</Link>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <CustomMobileLink
              href={"/admissions"}
              title="Admissions"
              toggle={handleClick}
            >
              Admissions
            </CustomMobileLink>
            <Dropdown>
              <DropdownTrigger>
                <Button
                  className="relative group uppercase outline-none bg-none m-0 text-purewhite text-[18px]"
                  href={"#"}
                  title="Academic Programmes"
                  variant="light"
                >
                  Academic Programmes <FaAngleDown />
                  <span
                    className={`h-[6px] inline-block  bg-gradient-to-r from-seccolor to-purewhite absolute -z-10 left-0 -bottom-0.5 group-hover:w-full  hover:text-gray text-gray transition-[width] ease duration-500 ${
                      currentPath === href ? "w-full" : "w-0"
                    }`}
                  >
                    &nbsp;
                  </span>
                </Button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Static Actions">
                <DropdownItem key="chew">
                  <Link to={"/department/chew"}>
                    Community Health Extension Workers (CHEW)
                  </Link>
                </DropdownItem>
                <DropdownItem key="eht">
                  <Link to={"/department/eht"}>
                    Environmental Health Technology (EHT)
                  </Link>
                </DropdownItem>
                <DropdownItem key="hep">
                  <Link to={"/department/hep"}>
                    Health Education and Promotion (HEP)
                  </Link>
                </DropdownItem>
                <DropdownItem key="p-health">
                  <Link to={"/department/public-health"}>Public Health</Link>
                </DropdownItem>
                <DropdownItem key="him">
                  <Link to={"/department/him"}>
                    Health Information Management (HIM)
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <CustomMobileLink
              href={"/contact"}
              title="Contact"
              toggle={handleClick}
            >
              Contact us
            </CustomMobileLink>
          </motion.div>
        ) : null}

        <button
          className=" absolute right-8 top-12 flex-col justify-center items-center hidden lg:flex"
          onClick={handleClick}
        >
          <span
            className={`bg-maincolor block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm ${
              isOpen ? "rotate-45 translate-y-1" : " -translate-y-0.5"
            }`}
          ></span>
          <span
            className={`bg-maincolor block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm my-0.5 ${
              isOpen ? "opacity-0" : "opacity-100"
            }`}
          ></span>
          <span
            className={`bg-maincolor block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm ${
              isOpen ? "-rotate-45 -translate-y-1" : "translate-y-0.5"
            }`}
          ></span>
        </button>
      </div>
    </nav>
  );
}

export default Nav;
