import React from "react";
import PageHeader from "../components/PageHeader";
import Layout from "../components/Layout";
import {Link} from "react-router-dom";

const courses = [
  {
    id: 1,
    courseName: "Community Health Extension Workers (CHEW)",
    courseLink: "/department/chew",
  },
  {
    id: 2,
    courseName: "Environmental Health Technology (EHT)",
    courseLink: "/department/eht",
  },
  {
    id: 3,
    courseName: "Health Education and Promotion (HEP)",
    courseLink: "/department/hep",
  },
  {
    id: 4,
    courseName: "Public Health",
    courseLink: "/department/public-health",
  },
  {
    id: 5,
    courseName: "Health Information Management (HIM)",
    courseLink: "/department/him",
  },
];

const entry = [
  {
    id: 1,
    title: "Diploma",
    content:
      "Minimum of Five (5) credits at not more two sittings which must include English, Mathematics, Physics, Chemistry, Biology/Health Science and any other relevant Science subjects",
  },
  {
    id: 2,
    title: "Certificate",
    content:
      "Minimum of Three (3) credits at not more two sittings which must include English, Mathematics, Biology/Health Science and any other relevant Science subjects",
  },
  {
    id: 1,
    title: "Direct Entry",
    content:
      "Minimum of Five (5) credits at not more two sittings which must include English, Mathematics, Physics, Chemistry,  and any subject in addition to relevant professional certificate",
  },
];

function Admissions() {
  return (
    <>
      <PageHeader title="Admissions" />
      <div className="flex flex-col gap-8 py-24 sm:py-12 px-64 md:px-32 sm:px-8 justify-center relative">
        <div className="shadow-md p-8 w-full">
          <h2 className="text-2xl font-bold text-seccolor mb-4">
            Available Courses for 2023/2024 Academic Session
          </h2>
          <ol className="space-y-3 list-decimal">
            {courses.map((course) => (
              <Link
                to={course.courseLink}
                key={course.id}
                className="underline underline-offset-4 hover:text-seccolor transition-all duration-150 ease"
              >
                <li>{course.courseName}</li>
              </Link>
            ))}
          </ol>
        </div>
        <div className="admissions absolute top-5 md:-top-10 right-10 md:right-0 bg-seccolor text-purewhite flex items-center justify-center text-center  font-bold text-3xl md:text-md p-2 rounded-md w-64 h-64 md:w-full md:h-auto">
          <h2>Obtain your <br /> Form at the <br /> School Premises</h2>
        </div>
        <div className="mt-12 ">
          <h2 className="text-2xl font-bold text-seccolor mb-4">
            Entry Requirements
          </h2>
        </div>
        <div className="flex md:flex-col gap-8">
          {entry.map((req) => (
            <div key={req.id} className="shadow-md p-4">
              <h2 className="text-lg text-maincolor font-bold uppercase">
                {req.title}
              </h2>
              <p>{req.content}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Admissions;
