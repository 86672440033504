import React from "react";
import { motion } from "framer-motion";
import { FaHome, FaSearch, FaTasks } from "react-icons/fa";

const missionVision = [
  {
    id: 1,
    title: "Mission",
    content:
      "To empower students with the knowledge, skills, and ethical values required to excel in Nigeria's dynamic healthcare sector. We are committed to providing high-quality education, practical experience, and cultural awareness, preparing our graduates to contribute meaningfully to the health and well-being of the Nigerian population.",
    icon: <FaHome />,
    delay: 0.3,
  },
  {
    id: 2,
    title: "Vision",
    content:
      "To be a leading institution in Nigeria, recognized for producing exceptional healthcare professionals who are both nationally and internationally competitive. We aspire to play a pivotal role in addressing healthcare challenges in Nigeria by nurturing graduates who are not only academically proficient but also culturally sensitive and deeply committed to making a positive impact on healthcare delivery.",
    icon: <FaSearch />,
    delay: 0.6,
  },
  {
    id: 3,
    title: "Aims and Objectives",
    content:
      "The institution aims to produce culturally aware graduates who actively impact their communities, emphasizing research, ethical standards, and professionalism in healthcare. With a focus on student success, sustainability, and ongoing engagement with alumni, the college is committed to addressing the healthcare challenges in Nigeria and contributing to the betterment of the nation's healthcare landscape.",
    icon: <FaTasks />,
    delay: 0.9,
  },
];

const textShow = {
  initial: {
    opacity: 0,
    x: 200,
  },
  whileInView: {
    opacity: 1,
    x: 0,
  },
};

function MissionVission() {
  return (
    <div className="mvision-us flex lg:flex-col gap-8 p-24 lg:p-16 md:p-12 sm:p-4 items-center justify-center bg-gray text-darkgray">
      <div>
        <div className="grid w-full grid-cols-9 gap-4 md:gap-4 p-6">
          {missionVision.map((items) => (
            <motion.div
              key={items.id}
              initial={{ opacity: 0, y: 100 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: items.delay, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="col-span-3 lg:col-span-9 bg-purewhite text-maincolor border-maincolor border border-solid p-4 pt-12 rounded-md shadow-2xl shadow-milk text-justify space-y-6"
            >
              <div className="flex items-center justify-center">
                <span className="text-6xl bg-seccolor text-purewhite rounded-full p-6">
                  {items.icon}
                </span>
              </div>
              <h2 className="text-2xl font-bold text-center text-maincolor uppercase">
                {items.title}
              </h2>
              <p className="text-md sm:text-sm font-light text-darkgray p-3">
                {items.content}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MissionVission;
