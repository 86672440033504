import React from "react";
import Layout from "../components/Layout";
import Hero from "../components/hero/Hero";
import About from "../components/home/About";
import MissionVission from "../components/MIssionVision";
import FromFounder from "../components/FromFounder";
import Courses from "../components/home/Courses";

function index() {
  return (
    <div>
        <Hero />
        <About />
        <MissionVission />
        <FromFounder />
        <Courses />
    </div>
  );
}

export default index;
