import React from "react";
import { motion } from "framer-motion";
import PageHeader from "../../components/PageHeader";
import FounderImage from "../../images/founder.jpg";

function Proprietor() {
  return (
    <>
      <PageHeader title="About CP Sani Abdullahi Salihu RTD" />
      <div className="flex md:flex-col gap-8 py-24 sm:py-12 px-64 md:px-32 sm:px-8 justify-center">
        <div className="w-1/3 md:w-full h-fit relative shadow-md p-2 bg-milk flex flex-col items-center rounded-lg">
          <img
            src={FounderImage}
            alt="founder"
            className="w-full rounded-tl-lg rounded-tr-lg mb-1"
          />
          <figcaption className="bg-black w-full text-center p-1 text-milk font-bold">
            CP Sani Abdullahi Salihu RTD
          </figcaption>
          <figcaption className="text-center italic">
            Philanthropist, Seasoned Business Leader, and CEO of Maikunu Global
            Investments
          </figcaption>
        </div>
        <div className="w-2/3 md:w-full">
          <div className=" text-darkgray text-justify space-y-4">
            <motion.p
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="leading-6"
            >
              CP Sani Abdullahi Salihu, a distinguished and multifaceted
              entrepreneur, stands as a beacon of inspiration in both the
              business and philanthropic worlds. As the founder and CEO of
              Maikunu Global Investments, a renowned company with interests
              spanning building construction, trading, and farming, he has
              demonstrated his astute leadership, dedication to community
              development, and unwavering commitment to excellence.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="leading-6"
            >
              With a career spanning several decades, CP Sani Abdullahi has
              honed his business acumen and achieved remarkable success in the
              corporate realm. As the head of Maikunu Global Investments, he has
              overseen the company&apos;s diverse portfolio, demonstrating a
              remarkable ability to navigate the intricacies of building
              construction, international trading, and sustainable agriculture.
              His leadership has led to the company&apos;s growth, earning a
              reputation for quality, innovation, and reliability.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="leading-6"
            >
              Beyond his business endeavors, CP Sani Abdullahi Salihu is a
              dedicated philanthropist, investing his resources and expertise in
              numerous initiatives aimed at improving the well-being of his
              community and beyond. His philanthropic efforts encompass
              education, healthcare, and social welfare, reflecting his deep
              commitment to making a positive impact on the lives of those less
              fortunate.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="leading-6"
            >
              He is an active participant in community development projects,
              striving to uplift and empower the underprivileged. His engagement
              extends to partnerships with educational institutions, healthcare
              facilities, and charitable organizations, all driven by the belief
              that positive change can be realized through collective effort.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="leading-6"
            >
              CP Sani Abdullahi Salihu is guided by a vision of a prosperous,
              sustainable future. His values of integrity, diligence, and a
              sense of social responsibility permeate every aspect of his work,
              from business leadership to philanthropy. His unwavering
              commitment to ethical practices, both in the corporate world and
              his philanthropic ventures, is a testament to his dedication to
              creating a better world.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 200 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.5 },
              }}
              viewport={{ once: true }}
              className="leading-6"
            >
              CP Sani Abdullahi Salihu exemplifies the rare blend of business
              prowess and social conscience. Through Maikunu Global Investments,
              his impact is felt not only in the boardroom but also in the lives
              of those touched by his philanthropic initiatives. His legacy is
              one of inspiration, reminding us that the most successful leaders
              are those who extend their reach to improve the lives of others
              and build a brighter future for all.
            </motion.p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Proprietor;
